<template>
  <div class="app-main-content" ref="main">
    <div class="container d-flex">
      <settings-left-menu></settings-left-menu>
      <card title="基础数据" style="width: calc(100% - 326px)">
        <template #tools>
          <div class="item">
            <a-button class="search" @click="showModal">新增参数</a-button>
          </div>
          <div class="right">
            <div class="item">
              <span class="label">搜索</span>
              <a-input class="search-input" v-model="keyword" placeholder="请输入关键字">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button class="search" @click="onSearch">查询</a-button>
            </div>
          </div>
        </template>
        <div class="main">
          <div style="margin: 0 20px 20px">
            <a-table style="min-width: 1080px;" tableLayout="fixed" :columns="columns" :data-source="datas" :expandIcon="expandIcon"
                     :rowKey="record => record.oid" :defaultExpandAllRows="true"
                     :pagination="pagination" @change="paginationChange">
              <span class="field_type" slot="field_type" slot-scope="text">
                {{ getCatName(text) }}
              </span>
              <span class="action" slot="action" slot-scope="text, record">
                <a @click="addChildData(text,record)">新增</a>
                <a @click="updateData(text,record)">更新</a>
                <a @click="deleteData(text,record)">删除</a>
              </span>
            </a-table>
          </div>
        </div>
      </card>
    </div>
    <a-modal
        title="参数"
        width="680px"
        :visible="visible"
        :getContainer="() => this.$refs.main"
        :maskClosable="false"
        @cancel="handleCancel"
        @ok="handleOk"
    >

      <a-form-model layout="vertical" ref="ruleForm" :model="form" :rules="rules" class="edp-form">
        <div class="line-2">
          <a-form-model-item label="类型" prop="cat_type">
            <!--<a-select v-model="form.cat_type">-->
            <!--  <a-select-option v-for="item in cat_types" :key="item.label" :value="item.value">{{-->
            <!--      item.label-->
            <!--    }}-->
            <!--  </a-select-option>-->
            <!--</a-select>-->
            <a-select v-model="form.cat_type" :options="cat_types">
              <template #option="{ label }">
                {{ label }}
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="编号" prop="cat_code">
            <a-input v-model="form.cat_code"/>
          </a-form-model-item>
          <a-form-model-item label="类别名称">
            <a-input v-model="form.cat_name"/>
          </a-form-model-item>
          <a-form-model-item label="数据类型">
            <a-select v-model="form.field_type">
              <a-select-option v-for="item in field_types" :key="item.label" :value="item.value">{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="数据值">
            <a-input v-model="form.field_value"/>
          </a-form-model-item>
          <a-form-model-item label="说明">
            <a-input v-model="form.remark"/>
          </a-form-model-item>
          <a-form-model-item label="扩展字段A">
            <a-input v-model="form.temp10"/>
          </a-form-model-item>
          <a-form-model-item label="扩展字段B">
            <a-input v-model="form.temp20"/>
          </a-form-model-item>
        </div>
      </a-form-model>
      <!--  <template #footer>
              <a-checkbox v-model="isCreate">
                继续创建下一个
              </a-checkbox>
              <div class="button">
                <a-button @click="handleCancel">取消</a-button>
                <a-button class="ok" @click="handleOk">确定</a-button>
              </div>
            </template>-->
    </a-modal>
  </div>
</template>

<script>
import {fetch} from "@/utils/request";
import settingsLeftMenu from "@/components/settingsLeftMenu.vue";

export default {
  data() {
    return {
      columns: [
        {title: '类型', dataIndex: 'cat_type', width: 250},
        {title: '编号', dataIndex: 'cat_code', width: 180},
        {title: '类别名称', dataIndex: 'cat_name', width: 120},
        {title: '数据类型', dataIndex: 'field_type', width: 120, scopedSlots: {customRender: 'field_type'},},
        {title: '数据值', dataIndex: 'field_value'},
        {title: '说明', dataIndex: 'remark'},
        {title: '更新时间', dataIndex: 'update_time', width: 160},
        {title: '操作', dataIndex: 'action', scopedSlots: {customRender: 'action'}, width: 150},
      ],
      datas: [],
      cat_types: [],
      field_types: [],
      // 搜索关键帧
      keyword: '',
      // 当前页
      page_index: 1,
      // 当前条
      page_size: 10,
      // 总条
      total: 0,
      // 总页
      total_page_count: '',
      // 是否显示新增参数页面
      visible: false,
      isChildren: false,
      form: {},
      rules: {
        cat_type: [
          {required: true, message: '请选择类型', trigger: 'blur'}
        ],
        cat_code: [
          {required: true, message: '请输入编号', trigger: 'blur'}
        ],
      },
      isCreate: false
    }
  },
  components: { settingsLeftMenu },
  created() {
    this.getBasicData()
    this.getTypeData()
  },
  computed: {
    pagination() {
      return {
        current: this.page_index,
        pageSize: this.page_size,
        showSizeChanger: true,
        total: this.total,
        hideOnSinglePage: true
      }
    }
  },
  methods: {
    getCatName(cat_code) {
      let find = this.field_types.find(item => item.value === cat_code)
      if (find) {
        return find.label
      }
      return cat_code
    },
    // 获取页面基础数据
    async getBasicData() {
      const res = await fetch('post', '/cpy/cat/getall', {
        keyword: this.keyword,
        page_index: this.page_index,
        page_size: this.page_size
      })
      if (res.status === 0) {
        this.datas = res.data.categories
        this.total = res.data.page.total
      }
    },
    // 获取类型基础数据
    async getTypeData() {
      const res1 = await fetch('post', '/bascat/retrieve', {cat_type: 'cat_type'})
      const res2 = await fetch('post', '/bascat/retrieve', {cat_type: 'datatype'})
      if (res1.status === 0) {
        this.cat_types = res1.data.datas.map(item => ({value: item.cat_code, label: item.cat_name}))
      }
      if (res2.status === 0) {
        this.field_types = res2.data.datas.map(item => ({value: item.field_type, label: item.cat_name}))
      }
    },
    // 页码跟条数发生变化
    paginationChange(pagination) {
      this.page_index = pagination.current
      this.page_size = pagination.pageSize
      this.getBasicData()
    },
    // 搜索
    onSearch() {
      this.page_index = 1
      this.getBasicData()
    },
    // 新增子数据
    addChildData(text, record) {
      this.form.level = (parseInt(record.level) + 1).toString()
      this.form.routemap = record.routemap + '/' + record.oid
      this.showModal()
    },
    // 更新数据
    updateData(text, record) {
      this.form = {...record}
      this.showModal()
    },
    // 删除数据
    deleteData(text, record) {
      this.$confirm({
        title: '温馨提示',
        content: '确定删除吗？',
        onOk: async () => {
          const res = await fetch('post', '/cpy/cat/delete', {oid: record.oid})
          if (res.status === 0) {
            this.getBasicData()
            this.$message.success('删除成功')
          } else {
            this.$message.error(res.message)
          }
        },
        onCancel() {
        },
      })
    },
    // 打开参数页面
    showModal() {
      this.visible = true
    },
    // 取消
    handleCancel() {
      this.visible = false
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
      }
      this.form = {}
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const res = this.form.oid ? await fetch('post', '/cpy/cat/update', this.form) : await fetch('post', '/cpy/cat/create', this.form)
          if (res.status === 0) {
            this.form.oid ? this.$message.success('修改数据成功') : this.$message.success('新增数据成功')
            this.handleCancel()
            this.getBasicData()
          } else {
            this.$message.error(res.message)
          }
        }
      })
    },
    // 更改树形图标
    expandIcon(props) {
      if (props.record.children.length === 0) return <span style="margin-right:19px"></span>
      if (props.expanded) {
        return (
            <a class="table-icon" onClick={(e) => {
              props.onExpand(props.record, e);
            }}>
              <a-icon type="down"/>
              {" "}
            </a>
        )
      } else {
        return (
            <a class="table-icon" onClick={(e) => {
              props.onExpand(props.record, e);
            }}>
              <a-icon type="right"/>
              {" "}
            </a>
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  .container {
    width: 100%;
    height: 100%;

    /deep/ .edp-card-body {
      overflow-y: auto;
    }
  }

  .main {
    .operate {
      display: flex;
      justify-content: right;
      align-items: center;
      margin: 14px 34px 14px 20px;

      .left {
        .search {
          width: 228px;
          height: 26px;

          /deep/ .ant-input {
            padding: 0;
            height: 26px;
          }

          /deep/ .ant-input-suffix {
            font-size: 17px;
            right: 24px;

            .ant-input-search-icon {
              color: rgba(0, 0, 0, 0.8);
            }
          }
        }
      }

      .right {
        .button {
          height: 30px;
          border-radius: 3px;
          color: #fff;
          background-color: var(--primary-color);
          font-weight: 500;
        }
      }
    }

    /deep/ .ant-table-wrapper {
      table {
        //border: none;
        //
        //thead {
        //  th {
        //    border-right: none;
        //  }
        //}
        //
        //tbody {
        //  td {
        //    border-right: none;
        //  }
        //}

        //.ant-table-thead {
        //  tr {
        //    th {
        //      background-color: #fff;
        //    }
        //  }
        //}

        .ant-table-thead {
          tr th:first-child {
            .ant-table-header-column {
              margin-left: 19px;
            }
          }
        }

        .ant-table-row-cell-break-word {
          .table-icon {
            color: #000;
          }
        }

        .action {
          a {
            color: var(--primary-color);
            margin-right: 7px;
          }
        }
      }

      .ant-table-pagination {
        float: none !important;
        text-align: center;
      }
    }
  }

  /deep/ .ant-modal-root {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding-bottom: 0;

      .content {
        .ant-form {
          width: 320px;
          height: 320px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }

        .ant-form-item {
          height: 80px;
          padding-bottom: 0;
          margin-bottom: 0;

          .ant-form-item-label {
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 0;
            margin-bottom: 7px;

            .ant-form-item-required {
              &::before {
                display: none;
              }

              &::after {
                display: inline-block;
                margin-right: 4px;
                color: #f5222d;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: '*';
              }
            }
          }

          .ant-form-item-control-wrapper {
            .ant-input {
              width: 307px;
              height: 30px;
            }

            .ant-select-arrow {
              color: #494949;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      button {
        width: 60px;
        height: 30px;
        border-radius: 2px;
        color: #616161;
      }

      .ant-btn-primary {
        color: #fff;
        background: var(--primary-color);
      }
    }
  }
}
</style>
